export default [
  {
    header: 'Dashboards',
    img_svg: 'dashboard_black',
    title: 'home',
    route: 'dashboard-analytics',
    resource: 'Dashboard',
    action: 'manage',
    newLabel: false,
    exclude: ['realty']
  },
  {
    title: 'castingCalls',
    img_svg: 'megafono_black',
    route: 'casting_calls',
    resource: 'Creator',
    action: 'manage',
    newLabel: false,
    avaible_with_no_session: true
  },
  {
    title: 'proposals',
    img_svg: 'proposals_black',
    route: 'my_work',
    params: { section: 'proposals' },
    resource: 'Creator',
    action: 'manage',
    newLabel: false,
    disabled: false,
  },
  {
    title: 'marketplace',
    img_svg: 'marketplace_black',
    route: 'search_proposal',
    resource: 'Dashboard',
    action: 'manage',
    exclude: ['creator', 'realty'],
    avaible_with_no_session: true,
  },
]
