import ability from '@/libs/acl/ability'

export default [
  {
    header: 'My work',
    title: 'my_work',
    img_svg: 'colaboration_black',
    params: { section: 'campaigns' },
    route: 'my_work_brands',
    resource: 'Brand',
    action: 'manage',
    exclude: ['realty']
  },
  {
    title: 'chat',
    icon: 'MessageCircleIcon',
    route: 'chat-proposal',
    action: 'manage',
    resource: 'Dashboard',
    exclude: ['realty']
  },
  {
    header: 'Buscador',
    title: 'search',
    img_svg: 'search_black',
    route: 'auth-search',
    resource: 'Brand',
    action: 'manage',
    disabled: !ability.can('manage', 'view_searcher'),
    permission: 'view_searcher',
    exclude: ['realty']
  },
  {
    title: 'analyzer',
    img_svg: 'analyzer_black',
    route: 'analyzer',
    action: 'manage',
    resource: 'Dashboard',
    disabled: !ability.can('manage', 'view_analyzer'),
    permission: 'view_analyzer',
    newLabel: false,
    exclude: ['creator', 'realty']
  },
]
