import ability from '@/libs/acl/ability'

export default [
  {
    header: 'Campañas',
    img_svg: 'campaign_int_black',
    title: 'campaigns',
    route: 'campaigns',
    resource: 'Brand',
    action: 'manage',
    disabled: !ability.can('manage', 'view_campaign'),
    permission: 'view_campaign',
    exclude: ['realty']
  },
  {
    header: 'Listas',
    icon: 'ListIcon',
    title: 'list',
    route: 'lists',
    resource: 'Brand',
    action: 'manage',
    disabled: !ability.can('manage', 'view_list'),
    permission: 'view_list',
    exclude: ['realty']
  },
  {
    header: 'Tracking Links',
    icon: 'LinkIcon',
    title: 'tracking_links',
    route: 'tracking_links',
    resource: 'Dashboard',
    action: 'manage',
    verify_plan: true,
    plan_benefit: 'view_tracking_links',
    exclude: ['realty']
  },
]
