<template>
  <div
    class="app-content content"
    :class="[{'show-overlay': $store.state.app.shallShowOverlay}, $route.meta.contentClass]"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >
      <slot name="breadcrumb">
        <app-breadcrumb />
      </slot>
      <div class="content-body" :class="{'mt-6-custom-alert-creator': handleMarginBanner()}">
        <transition
          :name="routerTransition"
          mode="out-in"
        >
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { getUserData } from '@/libs/utils/user'

export default {
  components: {
    AppBreadcrumb,
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()

    return {
      routerTransition, contentWidth,
    }
  },
  methods: {
    handleMarginBanner() {
      return (this.$route.name !== 'home_search' && getUserData() && getUserData().main_group && getUserData().main_group.name === 'creator') && 
      (this.$route.name !== 'setting-public-profile') && (this.metaInfo() === null || this.metaInfo().vanity_name === null || this.metaInfo().birthday === '')
    },
    metaInfo() {
      return getUserData() && getUserData().meta_info
    }
  }
}
</script>

<style lang="scss">
.mt-6-custom-alert-creator {
  margin-top: 6em;
  @media(max-width: 1199px) {
    margin-top: 9em;
  }
  @media(max-width: 761px) {
    margin-top: 11em;
  }
  @media(max-width: 475px) {
    margin-top: 13em;
  }
  @media(max-width: 371px) {
    margin-top: 14.55em;
  }
  @media(max-width: 356px) {
    margin-top: 16em;
  }
}
</style>
